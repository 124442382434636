html, body {
  height: 100%;
}

.full-height {
  height: 100%;
}

#root{
  height: 100%;
}

.header{
  height: 10%;
}

.form-wrapper{
  height: 80%;
}

.imagem-cabecalho{
  max-height: 100%;
  max-width: 100%;

}
/* Variaveis */

:root{
  /* Cores */
  --dommus-color:#0a71b2;
  --dommus-color-2:#f19d1f;
  --dommus-color-secondary:#64B5F6;
  --dommus-color-cinza:#868aa8;
  --dommus-color-green:#4CAF50;
  --dommus-color-red:#f44336;
  --dommus-color-dark:#353252;
  --dommus-shadow:0 2px 4px rgba(0,0,0,0.1);
  --dommus-b-radius: 5px;
  --dommus-overflow-width:6px;
  --dommus-overflow-height:6px;


  /*  */
  --smooth:200ms ease-in-out;
}